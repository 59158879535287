var prodcat = prodcat || {};

(function ($) {
  $(document).on('product.init', '.js-product.product-full', function () {
    if (this.loaded) {
      return null;
    }
    this.loaded = true;
    var $product = $(this);

    // Initialize app code to render.
    initalizeStoreCheck($product, $product.data('sku-base-id'));
  });

  // On sku change, refresh the store check.
  $(document).on('product.skuSelect', '.js-product.product-full', function (e, skuData) {
    var $product = $(this);

    initalizeStoreCheck($product, skuData);
  });

  // Helper function to init store check.
  function initalizeStoreCheck($product, skuBaseId) {
    if (!skuBaseId) {
      return null;
    }
    var prod_id = $product.data('product-id');
    var prodData = prodcat.data.getProduct(prod_id);
    var skuData = prodcat.data.getSku(skuBaseId);
    var product = { data: {} };

    $.extend(product.data, prodData, skuData);
    // Load dependency library.
    $(document).trigger('invis:init', {
      skuId: product.data.SKU_ID,
      product: product.data
    });
  }
})(jQuery);
